import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Polityka prywatności" mdxType="Seo" />
    <div style={{
      "textAlign": "justify"
    }}>
      <h1>{`Polityka prywatności`}</h1>
      <p>{`Korzystając z serwisu kamiko.studio ("Serwis") wyrażają Państwo zgodę na przetwarzanie Państwa danych osobowych zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, RODO) w celu umożliwienia osobie pozostawiającej dane w formularzu kontaktu z KAMIKO.`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Administratorem danych osobowych podanych w formularzu jest Iga Kostrzewska, prowadząca działalność gospodarczą pod nazwą KAMIKO Iga Kostrzewska, ul. Chyża 5, 73-210 Recz, NIP: 5941590864, REGON: 368009527 („KAMIKO”),`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Dane osobowe będą przetwarzane w celu umożliwienia osobie pozostawiającej dane w formularzu kontaktu z KAMIKO i umożliwienia KAMIKO odpowiedzi na zadane pytania.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Podanie danych osobowych jest dobrowolne, ale konieczne dla umożliwienia Państwu kontaktu z KAMIKO.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Przetwarzanie podanych przez Państwa danych osobowych w czasie kontaktu z KAMIKO odbywa się w oparciu o dobrowolnie wyrażoną zgodę (art. 6 ust. 1 lit. b) RODO).`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Dane będą mogły być również przetwarzane niezależnie od wyrażonej zgody, w związku z dochodzeniem/obroną roszczeń (art. 6 ust. 1 lit. f) RODO).`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mają Państwo prawo do żądania od administratora tj. KAMIKO dostępu do Państwa danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych oraz prawo do złożenia oświadczenia o cofnięciu każdej wyrażonej zgody w każdym czasie. Cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Państwa dane osobowe będą przetwarzane przez okres nie dłuższy niż przez czas trwania kontaktu z KAMIKO. Państwa dane osobowe będą mogły być przetwarzane przez okres przedawnienia roszczeń związanych z przetwarzaniem Państwa danych osobowych w celach określonych w powyższej deklaracji zgody.`}</p>
        </li>
      </ol>
      <h1>{`Cookies`}</h1>
      <p>{`Ta strona używa plików cookies (cisteczka). Korzystając z niej, wyrażają Państwo zgodę na używanie przez tę stronę plików cookies zgodnie z Polityką plików „cookies” serwisu kamiko.studio.`}</p>
      <p>{`Polityka plików “cookies” serwisu kamiko.studio:`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Poprzez piki “cookies” należy rozumieć dane informatyczne przechowywane w urządzeniach końcowych użytkowników, przeznaczone do korzystania ze stron internetowych. W szczególności są to pliki tekstowe, zawierające nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pliki cookies przeznaczone są do korzystania ze stron serwisu. Iga Kostrzewska, prowadząca działalność gospodarczą pod nazwą KAMIKO Iga Kostrzewska, ul. Chyża 5, 73-210 Recz, NIP: 5941590864, REGON: 368009527 („KAMIKO”) wykorzystuje te pliki do tworzenia anonimowych statystyk z wyłączeniem możliwości identyfikacji użytkownika.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pliki cookies wykorzystywane przez partnerów KAMIKO strony internetowej, w tym w szczególności użytkowników strony internetowej, podlegają ich własnej polityce prywatności.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików cookies na urządzeniu końcowym Użytkownika. Ustawienia te mogą zostać zmienione przez Użytkownika w taki sposób, aby blokować automatyczną obsługę “cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na urządzenia użytkownika.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`KAMIKO informuje, że zmiany ustawień w przeglądarce internetowej użytkownika mogą uniemożliwić poprawne działanie Serwisu.`}</p>
        </li>
      </ol>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      